import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero/private-security"

const PrivateSecurityPage = () => (
  <Layout>
    <Seo title="من نحن؟" />
    <Hero />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <StaticImage
              src="../images/private.jpg"
              quality={65}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Clavicon Profile"
              placeholder="tracedSVG"
            />
          </div>
          <div className="column is-three-fifths">
            <div className="content">
              <h3 className="mt-0">
              احترافية والسرية – حلول أمنية مُصممة لتناسب احتياجات الأفراد وعائلاتهم
              </h3>
              <p>
              نحن في شركة كلافيكون ندعمك في جميع مجالات حياتك من خلال حزمة خدمات شاملة لتأمينك وتأمين عائلتك وممتلكاتك. وحيث إن ظروف حياتك الخاصة تتطلب برنامجًا أمنيًا، فإننا نصممه خصيصًا ليتوافق تمامًا مع احتياجاتك الأمنية. طريقة عملنا تتسم بالسرية والاحترام الكامل لحياتك الخاصة.
              </p>
              <p>تشمل قائمة خدماتنا أشياءٍ أخرى من بينها :</p>
              <ul>
                <li>
                تحديد وتحليل الوضع الأمني في النطاق العائلي الضيق و/أو الواسع.
                </li>
                <li>
                تصميم وإقامة وتنفيذ إجراءات أمنية لتقليل المخاطر.
                </li>
                <li>
                بناء وإدخال البُنى اللازمة لإدارة الطوارئ والأزمات.
                </li>
                <li>
                التوعية بشأن طرق التعامل الوقائي وردود الأفعال.
                </li>
              </ul>
              <p>
              لمزيد من التفاصيل حول باقة خدماتنا "الأمن الخاص" يٌمكنك التواصل معنا في أي وقت لإجراء مقابلة شخصية.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PrivateSecurityPage
